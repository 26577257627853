import React from 'react';
import {BsTwitter, BsInstagram, BsBehance, BsMedium} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa';
import {ImLinkedin2, ImGithub} from 'react-icons/im';



const SocialMedia = () => {
  return (
    <div className="app__social" >
        <div>
            <a href="https://www.linkedin.com/in/krepoo/" target="_blank" rel="noreferrer"><ImLinkedin2 /></a>
        </div>
        <div>
            <a href="https://www.behance.net/krepoo" target="_blank" rel="noreferrer"><BsBehance /></a>  
        </div> 
        <div>
            <a href="https://www.instagram.com/3abdelba9i_kr/" target="_blank" rel="noreferrer"> <BsInstagram /></a> 
        </div> 
        <div>
           <a href="https://www.facebook.com/3abdelba9i/" target="_blank" rel="noreferrer"><FaFacebookF /></a> 
        </div> 
        <div>
            <a href="https://twitter.com/3abdelba9i" target="_blank" rel="noreferrer"><BsTwitter /></a>  
        </div>
        <div>
            <a href="https://medium.com/@contact.3abdelba9i" ><BsMedium /></a> 
        </div>
        <div>
            <a href="https://github.com/3abdelba9i" ><ImGithub /></a> 
        </div>
    </div>
  )
}

export default SocialMedia